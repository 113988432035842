import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "#333333",
  height: "800px",
  width: "800px",
  version: "1.1",
  id: "Layer_1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  viewBox: "0 0 512 512",
  "xml:space": "preserve",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("g", null, [
        _createElementVNode("path", { d: "M369.151,0L0.001,369.15L142.85,512l369.149-369.15L369.151,0z M47.286,369.15l10.908-10.908l47.782,47.782l23.642-23.642\n\t\t\tL81.837,334.6l10.909-10.909l23.711,23.711L140.1,323.76l-23.711-23.711l10.909-10.909l23.711,23.711l23.642-23.642\n\t\t\tl-23.711-23.711l10.909-10.909l47.782,47.782l23.642-23.642l-47.782-47.782l10.908-10.908l23.71,23.71l23.642-23.642l-23.71-23.71\n\t\t\tl10.908-10.908l23.711,23.711l23.642-23.642l-23.711-23.711l10.909-10.909l47.782,47.782l23.642-23.642l-47.782-47.782\n\t\t\tl10.908-10.908l23.71,23.711l23.642-23.642l-23.711-23.71L334.6,81.837l23.711,23.71l23.642-23.642l-23.711-23.712l10.908-10.908\n\t\t\tl95.564,95.564L142.85,464.714L47.286,369.15z" })
      ])
    ], -1)
  ])))
}
export default { render: render }